<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('message_sent_inbox_title')"></h2>
      </v-col>
    </v-row>
    <v-row class="row spacer-md">
      <v-col xs="12" md="6" lg="2">
        <search-message-sent ref="search-message-sent" @search="search" @keyup.enter="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search" v-html="$t('message_sent_search')"></v-btn>
    <v-btn color="secondary" @click="reset" v-html="$t('message_sent_reset')"></v-btn>
    <v-chip v-if="messagesErrorNr !== 0" variant="flat" class="chip-bright ma-2" v-html="errorMessagesBadge"> </v-chip>
    <v-chip v-if="messagesSentNr !== 0" variant="flat" class="chip-bright ma-2" v-html="sentMessagesBadge"> </v-chip>
    <v-data-table
      :multi-sort="true"
      :items="items"
      :headers="fields"
      :loading="loading"
      :items-length="totalElements"
      :items-per-page-options="$itemsPerPageOptions"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      @click:row="handleClickOnRow"
      dense
      :itemsLength="totalElements"
    >
      <template v-slot:[`item.priority`]="{ item }">
        <span v-if="item.priority === true">
          <v-icon dbmblueprimary="">mdi-star</v-icon>
        </span>
        <span v-else> <v-icon dbmpassivgrey="">mdi-star-outline</v-icon> </span>
      </template>
      <template v-slot:[`item.sent`]="{ item }">
        {{ $formatChDateTime(item.sent) }}
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        {{ JSON.stringify(item.tags) }}
      </template>
      <template v-slot:[`item.attachments`]="{ item }">
        <div v-if="item.attachments != null">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <span v-bind="props">
                <v-icon dbmblueprimary="" @click="downloadAttachment(item.uuid, item.attachmentIds, item.attachments)" :loading="loadingAttachment"
                  >mdi-attachment</v-icon
                ></span
              >
            </template>
            <span>{{ item.attachments.toString() }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.read`]="{ item }">
        {{ $formatChDateTime(item.read).substr(0, 10) }}
      </template>
      <template v-slot:[`item.subject`]="{ item }">
        <div v-if="item.subject.length > messageTitlePreviewLength">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <span v-bind="props">{{ item.subject.substr(0, messageTitlePreviewLength) }}</span>
            </template>
            <span> {{ item.subject }} </span>
          </v-tooltip>
        </div>
        <div v-else>
          {{ item.subject }}
        </div>
      </template>
      <template v-slot:[`item.messageType`]="{ item }">
        {{ getMessageTypeName(item.messageType) }}
      </template>
    </v-data-table>
    <!--    </div>-->
    <message-detail-sent :message="messageDetail" :showDialog="showDialog" @closeDialog="closeDialog"></message-detail-sent>
  </v-container>
</template>
<script lang="ts">
import { DTSHeaders } from '@/services/BackendService'
import { apiURL } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import _ from 'lodash'
import messageDetail from '@/views/messages/inbox/messageDetail.vue'
import MessageDetailSent from '@/views/messages/sent/messageDetailSent.vue'
import SearchMessageSent from '@/components/searchCards/searchMessageSent.vue'
import { useMessageTypesStore } from '@/store/enums/MessageTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'message_sent_read',
  data() {
    return {
      items: [],
      messageDetail: {},
      landing: true,
      loading: false,
      loadingAttachment: false,
      loadingError: false,
      loadingSent: false,
      showDialog: false,
      hover: false,
      messagesErrorNr: 0,
      messagesSentNr: 0,
      messageTitlePreviewLength: 60,
      totalElements: 0
    }
  },
  computed: {
    fields(): DTSHeaders {
      return [
        {
          title: this.$t('search_message_sent_receiveDate'),
          value: 'sent',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_messageType'),
          value: 'messageType',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_priority'),
          value: 'priority',
          isReduced: false,
          sortable: true,
          align: 'center'
        },
        {
          title: this.$t('search_message_sent_campaign'),
          value: 'campaign', //todo
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_subject'),
          value: 'subject',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_recipient'),
          value: 'recipientFullName',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_recipientAddress'),
          value: 'recipientMeansOfContact',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_attachments'),
          value: 'attachments',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_channel'),
          value: 'channel',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_tags'),
          value: 'tags',
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_read'),
          value: 'sent',
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sent_from'),
          value: 'from', // todo this makes no sense..
          isReduced: true,
          sortable: true,
          align: 'left'
        }
      ].filter((el) => !this.$store.state.SearchMessageSentStore.reduced || !el.isReduced)
    },
    sentMessagesBadge(): string {
      return `${this.messagesSentNr} ${this.$t('search_message_sent_unread_nr')}`
    },
    errorMessagesBadge(): string {
      return `${this.messagesErrorNr} ${this.$t('search_message_sent_error_unread_nr')}`
    },
    params(): any {
      return {
        senderFullName: this.$store.state.SearchMessageSentStore.senderFullName,
        messageTitle: this.$store.state.SearchMessageSentStore.messageTitle,
        creationDateFrom: this.$store.state.SearchMessageSentStore.creationDateFrom,
        creationDateTo: this.$store.state.SearchMessageSentStore.creationDateTo,
        sendingChannel: this.$store.state.SearchMessageSentStore.sendingChannel,
        messageType: this.$store.state.SearchMessageSentStore.messageType,
        deliveryStatus: this.$store.state.SearchMessageSentStore.deliveryStatus,
        recipientFullName: this.$store.state.SearchMessageSentStore.recipientFullName,
        attachments: this.$store.state.SearchMessageSentStore.attachments,
        read: this.$store.state.SearchMessageSentStore.read,
        reduced: this.$store.state.SearchMessageSentStore.reduced,
        tags: this.$store.state.SearchMessageSentStore.tags
      }
    },
    query(): any {
      return {
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['priority,desc', 'sent,asc'] })
      }
    }
  },
  components: {
    SearchMessageSent,
    MessageDetailSent,
    messageDetail
  },
  methods: {
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    handleClickOnRow(message) {
      if (this.loadingAttachment) return
      this.messageDetail = message
      this.openDialog()
    },
    search() {
      this.landing = false
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async countErrorMessages() {
      /* backend binding */
      this.loadingError = true
      try {
        const response = await this.axios.get(
          apiURL + '/messages/search',
          {
            params: { ...this.query, page: 0, size: 1, deliveryStatus: 'ERROR' }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.messagesErrorNr = await response.data.totalElements
      } catch (e) {
        showError(e)
      } finally {
        this.loadingError = false
      }
    },
    async countSentMessages() {
      /* backend binding */
      this.loadingSent = true
      try {
        const response = await this.axios.get(
          apiURL + '/messages/search',
          {
            params: { ...this.query, page: 0, size: 1, deliveryStatus: 'SENT' } // todo all unread mails to tab
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.messagesSentNr = await response.data.totalElements
      } catch (e) {
        showError(e)
      } finally {
        this.loadingSent = false
      }
    },
    async downloadAttachment(messageId, attachmentIds, filenames) {
      this.loadingAttachment = true
      try {
        // let attachmentId = item.attachmentIds.toString() // todo implement multiple attachments
        const result = await this.axios.get(apiURL + `/messages/${messageId}/attachments/${attachmentIds}`, {
          headers: {},
          responseType: 'blob'
        })
        fileCreator(result, filenames)
      } catch (e) {
        showError(e)
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingAttachment = false
      }
    },
    async load() {
      /* backend binding */
      if (this.landing) return
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(
          apiURL + '/messages/sent/search',
          { params: _.omit(this.query, ['reduced']) },
          { headers: { 'Content-Type': 'application/json' } }
        )
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
        this.countErrorMessages()
        this.countSentMessages()
      }
    },
    reset() {
      this.$refs['search-message-sent'].reset()
    },
    getMessageTypeName(item) {
      const lang = this.$route.params.upLang
      const messageTypes = useMessageTypesStore().messageTypes
      const messageType = messageTypes.find((messageType) => messageType.name === item)
      return messageType ? messageType[`name${lang}`] : item
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    useMessageTypesStore().fetchMessageTypes
  },
  async mounted() {
    if (this.$route.query.size) {
      await this.load()
    }
  }
})
</script>
